<template>
  <div class="home" style="height: 100%;">

    <div class="right">
      <HomeCardUi></HomeCardUi>
    </div>
    <div class="left-text">
        <div style="width: 70%;
        margin-left: 20%;
        margin-top: 40%;
        "
        >
        <div
        style="
        letter-spacing: .4em;
        color: #FAF8F9;
        font-size: 50px;
        "
        >
        分享知识
        记录笔记！
        </div>
        <p
        style="
        font-size: 13px;
        letter-spacing: .3em;
        "
        >
          一个只有我自己在用的多用户平台，哈哈，有够滑稽~
        </p>
        <div style="float: right;margin-top: 100px;">
          <ButtonGoToUi
          >
          <a href="http://qianyafan.top/#/login"
          style="text-decoration: none;color:#FAF8F9"
          >
          前往登录（只是为了好看）
          </a>
          </ButtonGoToUi>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import global from '@/api/global_variable.js'
import HomeCardUi from '@/components/ui/HomeCardUi'
import ButtonGoToUi from '@/components/ui/ButtonGoToUi'
export default {
    components: {
      HomeCardUi,
      ButtonGoToUi
    },
    data(){
      return {
        cardShow: true,
        activeIndex: this.$route.path,
        imgURL:process.env.VUE_APP_BASE_IMAGES_URL
      }
    },
    methods:{
      link_to_user(){
        if(this.$store.state.user.user.username != undefined){
          this.$router.push({path: '/user', query: {username:this.$store.state.user.user.username}})
        }else{
          this.$router.push({path: '/login'})
        }
      },
    }
}
</script>
<style lang="less" scoped>
  .right{
    width: 65%;height: 100%;
    float: right;
    animation: right-show 1.5s;
  }
  @keyframes right-show{
    from{
      margin-right: -65%;
      visibility: hidden;
    }
    to{

    }
  }
@keyframes left-text{
    0% {
      visibility: hidden;
      margin-left: -35%;
    }
    100% {
      // visibility: visible;
    }
  }
  .left-text{
     width: 35%;height: 100%;
     float: left;
     color: #FFFFFF;
      // margin-left: 0px;
     animation: left-text 1.5s;
  }
</style>
