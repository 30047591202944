<template>
  <div class="card">
      <div
      class="background-img-1"
      @click="linkTo('/blog')"
      >
      <p class="background-img-1-mask">
        <span class="title">找文章</span>
        <p
        class="content"
        >你可以在这里看到任何自己想看的知识，其中有很多博主技术分享以及笔记，还可以看到程序员在开发过程中遇到的任何问题
                         和问题的解决办法。
        </p>
      </p>
      </div>
      <div
      class="background-img-2"
      @click="linkTo('/quiz')"
      >
      <p class="background-img-1-mask">
        <span class="title">去提问</span>
        <p
        class="content"
        >你可以在这里向博主们提一些问题，例如你在开发中遇到的bug无法解决之类的，发送一个提问来@你想要的博主
                       帮你解决这个问题。
                       </p>
       </p>
      </div>
      <div
      class="background-img-3"
      @click="linkTo('/userSearch')"
      >
      <p class="background-img-1-mask">
        <span class="title">找用户，找友链</span>
        <p
        class="content"
        >找人？去这里可以找本站用户， 也可以找友情链接，访问他人的个人博客，快去吧！！</p>
      </p>
      </div>
  </div>
</template>

<script>
  export default{
    name:'HomeCardUi',
    methods: {
      linkTo(path) {
        this.$router.push(path)
      }
    },
  }
</script>

<style scoped>
  *{
    margin: 0px;
    padding: 0px;
  }
  .card {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #212121;
    display: flex;
    /* animation: card-show 1s; */
    /* gap: 5px; */
    /* padding: .4em; */
  }

  .background-img-1{
    background:url('https://qianyafan.oss-cn-beijing.aliyuncs.com/home_blog02.jpg') no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
  }
  .background-img-2{
    background:url('https://img3.wallspic.com/previews/1/7/0/0/30071/30071-ping_guo-duo_mei_ti-jia_ju-ta-dian_zi_she_bei-x750.jpg') no-repeat;
    background-position: 500px center;
    background-attachment: fixed;
    background-size: cover;
  }
  .background-img-3{
    background:url('https://img3.wallspic.com/previews/3/9/7/1/7/171793/171793-jiu_jin_shan-jian_zhu-chuang_kou-ju_xing-ta_kuai-x750.jpg') no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
  }
  .background-img-1-mask{
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
    transition: all .5s;
    /* background: #212121; */
    /* border: 1px solid #ff5a91; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    background-color: rgba(0,0,0,.3);
  }
  .card div:hover .background-img-1-mask{
    background-color: rgba(0,0,0,.1);
    flex:4;
  }
  .card div {
    height: 100%;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
    transition: all .5s;
    /* background: #212121; */
    /* border: 1px solid #ff5a91; */
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .card div:hover {
    flex: 4;
  }

  .card div .title {
    min-width: 14em;
    padding: .5em;
    text-align: center;
    transform: rotate(-90deg);
    transition: all .5s;
    text-transform: uppercase;
    color: #FAF8F9;
    letter-spacing: .5em;
    font-weight:bold;
    font-size: 20px;
  }
  .card div:hover .title {
    transform: rotate(0);
  }
  .card div .content {
    text-align: center;
    width: 24.88875rem;
    /* color: #ff568e; */
    font-size: 18px;
    color:#FAF8F9;
    /* display: none; */
    position: absolute;
    text-transform: uppercase;
    transition: all .5s;
    visibility: hidden;
    letter-spacing: .5em;
    /* font-weight:bold; */
    margin-top: 900px;

  }
  .card div:hover .content{
    /* display: inline-block; */
    visibility:visible;
    transition-delay:.2s;
    margin-top: 300px;
  }

 .background-img{
    /* position: absolute; */
    width: 100%;
    height: 100%;
    object-fit:cover;
  }
</style>
